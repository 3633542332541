import { render, staticRenderFns } from "./request.vue?vue&type=template&id=501971dd&scoped=true"
import script from "./request.vue?vue&type=script&lang=js"
export * from "./request.vue?vue&type=script&lang=js"
import style0 from "./request.vue?vue&type=style&index=0&id=501971dd&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_2aqslel7prtkk4onzp5d3vudie/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "501971dd",
  null
  
)

export default component.exports