import { render, staticRenderFns } from "./add.vue?vue&type=template&id=53847acf&scoped=true"
import script from "./add.vue?vue&type=script&lang=js"
export * from "./add.vue?vue&type=script&lang=js"
import style0 from "./add.vue?vue&type=style&index=0&id=53847acf&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_2aqslel7prtkk4onzp5d3vudie/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53847acf",
  null
  
)

export default component.exports